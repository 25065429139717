:root {
  --dark-grey: rgb(24, 26, 27);
  --light-grey: rgb(56, 61, 63);
  --lightest-grey: #e5e7eb;
  --light-disable: #eff3fc;
  --dark-disable: rgb(56, 61, 63);
  --white: #ffffff;
  --wheat: #dee2e6;
  --dark: black;
}

/* nav {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

h3 {
  margin: 5px;
  color: rgb(255, 255, 255);
  background: rgb(77, 77, 77);
  padding: 10px;
  border-radius: 8px;
}

h3:hover {
  cursor: pointer;
  color: rgb(77, 77, 77);
  background: rgb(255, 254, 228);
} */

body {
  /* width: calc(100vw - 10px); */
  background-color: #eff3fc !important;
}

/* width */
.slim-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.slim-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.slim-scroll::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle on hover */
.slim-scroll::-webkit-scrollbar-thumb:hover {
  background: #969494;
}

.main-head {
  color: #3a4758;
  font-size: 20px !important;
}

::-moz-selection {
  /* Code for Firefox */
  /* color: red; */
  background: #04bac7;
}

::selection {
  /* color: red; */
  background: #04bac7;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.h-100vh {
  height: 100vh;
}
.PhoneInputInput,
.PhoneInputInput:focus,
.PhoneInputInput:active,
.PhoneInputInput:target {
  border: none !important;
  outline: none !important;
}
.Cus-form-control {
  /* display: block; */
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input#form1 {
  background-image: url(/public/images/seach.png);
  background-repeat: no-repeat;
  text-indent: 25px;
  background-size: fill;
  background-position: left center;
  background-origin: content-box;
}
.explore-search {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.border-b5 {
  border-radius: 6px;
}
.border-search {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.rounded-6 {
  border-radius: 6px;
}
.border-chat {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.border-chat-attachment {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-inline-end: 1.5px solid #e9ecef;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.avatar {
  position: relative;
  width: 2.375rem;
  height: 2.375rem;
  cursor: pointer;
}
.avatar-social {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  /* cursor: pointer; */
}
.avatar-client {
  position: relative;
  width: 55px;
  height: 44px;
  /* cursor: pointer; */
}
.avatar .w-avatar {
  width: 100%;
  height: 100%;
}
.avatar-social .w-avatar {
  width: 100%;
  height: 100%;
}
.avatar-client .w-avatar {
  width: 100%;
  height: 100%;
}
.avatar .avatar-initial {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #69809a;
  font-weight: 600;
}
.avatar-social .avatar-initial {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #69809a;
  font-weight: 600;
}
.avatar.avatar-online:after,
.avatar.avatar-offline:after,
.avatar.avatar-away:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 3px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  box-shadow: 0 0 0 2px #fff;
}
.avatar.avatar-busy:after {
  content: "";
  position: absolute;
  bottom: 28px;
  right: 3px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  box-shadow: 0 0 0 2px #fff;
}
.avatar.avatar-online:after {
  background-color: #39da8a;
}
.avatar.avatar-alert:after {
  background-color: red;
}
.avatar.avatar-offline:after {
  background-color: #69809a;
}
.avatar.avatar-away:after {
  background-color: #fdac41;
}
.avatar.avatar-busy:after {
  background-color: #ff5b5c;
}
/* .bg-mask {
  background-image: url(/public/images/Mask.png);
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
} */

/* ************************************************************************************ Custom CSS ************************************************************************************  */

.auth-heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 25px;
}

.auth-tagline {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.form-label {
  font-size: 14px !important;
}

@media screen and (max-width: 992px) {
  .w-res-blocks {
    width: 60% !important;
  }
}
@media screen and (max-width: 768px) {
  .sm-d-none {
    display: none !important;
  }
  .w-days {
    height: 38px !important;
    width: 40px !important;
    font-size: 15px !important;
  }
  .w-res-75 {
    width: 75% !important;
  }
  .w-res-blocks {
    width: 80% !important;
  }
}
@media screen and (max-width: 425px) {
  .w-days {
    height: 30px !important;
    width: 32px !important;
    font-size: 13px !important;
  }
  .res-margin {
    margin-top: 0 !important;
  }
  .w-res-blocks {
    width: 100% !important;
  }
  .start-sm-gap {
    margin-left: "4.2rem";
  }
  .margin-start-res {
    margin-left: 0 !important;
  }
}
.hover-light:hover {
  background-color: #eff3fc !important;
  cursor: pointer;
  border-radius: 3px;
}
.hover-primary:hover {
  background-color: #04bac7 !important;
  cursor: pointer;
  border-radius: 3px;
}
.bg-register {
  background-image: url(/public/images/register.png);
  height: auto;
  width: 100%;
  /* background-size: fill; */
  background-color: black;
  background-position: center center;
  background-repeat: no-repeat;
}
a {
  text-decoration: none;
}
.verify-height {
  height: 60px;
  width: 100% !important;
}
.light-place::placeholder {
  color: #d0d5dd;
}
.light-place::-webkit-outer-spin-button,
.light-place::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.text-dark {
  color: black !important;
}
.rounded-5 {
  border-radius: 8px;
}
.rounded-start-5 {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.pointer {
  cursor: pointer !important;
}
.bg-border {
  background-image: url(/public/images/bg-border.png);
  height: 90px;
  width: 90px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.pe-8 {
  padding-right: 5rem;
}
.carousel .control-dots .dot.selected {
  display: none !important;
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #000 !important;
}
.carousel .control-next.control-arrow:before {
  border-left: 8px solid #000 !important;
}
.carousel-status {
  display: none !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #d8fcff !important;
  border: 1px solid #04bac7 !important;
  border-left: 4px solid #04bac7 !important;
  border-radius: 0px !important;
}
.border-start-primary {
  border-left: 4px solid #04bac7;
  border-radius: 5px;
}
.bg-light-grey {
  background-color: #e5e7eb !important;
}
.bg-light-sky {
  background-color: #f9fafb !important;
}
input[type="time"]::-webkit-datetime-edit-ampm-field {
  display: none;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}
.border-end-dangerC {
  border-right: 1px solid #dc3545 !important;
}
.border-dotted-secondary {
  border: 2px dotted #3a4758;
}
.bg-selected {
  background-color: #d8fcff;
}
.h-100-hidden {
  height: 100vh;
  overflow: hidden;
}
.bg-disable {
  background-color: #f9fafb;
}
.input-group-text {
  background-color: #f9fafb;
}
.form-control:disabled,
.form-control[readonly],
.form-select:disabled,
.form-select[readonly] {
  background-color: #f9fafb;
}
label {
  text-transform: capitalize;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #667085 !important;
  width: 204px;
  height: 24px;

  /* Text md/Regular */

  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  flex: none;
  order: 1;
  flex-grow: 1;
}
.no-icon::after {
  display: none !important;
}
@media screen and (max-width: 1400px) {
  .h-110-hidden {
    height: 110vh;
    overflow: hidden;
  }
  .w-res-blocks {
    width: 100%;
  }
}
@media screen and (min-width: 1400px) {
  .w-res-blocks {
    width: 70%;
  }
}
.alert-danger {
  background-color: #fffbfa !important;
  height: 50px !important;
}

.bg-payment {
  background: #9061f9 url(/public/images/dashboard/bg-payment.png) no-repeat;
  background-position: top right;
  background-size: contain;
}
.bg-pending {
  background: #f05252 url(/public/images/dashboard/bg-pending.png) no-repeat;
  background-position: top right;
  background-size: contain;
}
.bg-client {
  background: #31c48d url(/public/images/dashboard/bg-client.png) no-repeat;
  background-position: top right;
  background-size: contain;
}
.wh-140 {
  width: 100%;
  height: 140px;
  border: 1px solid #d2d2d2;
}
.credential-img {
  width: 100%;
  height: 135px;
}
.rounded-5 {
  border-radius: 12px;
}
.no-focus:focus {
  box-shadow: none !important;
}
.btn .btn-primary {
  display: flex !important;
  align-items: center !important;
}
.hover-primary2:hover {
  color: #fff;
  background-color: #04bac7 !important;
  border-radius: 6px;
}
.hover-primary2:focus {
  box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%) !important;
}
.tag-purple {
  background-color: #f9f5ff;
  color: #6941c6;
}
.tag-success {
  background-color: #ecfdf3;
  color: #027a48;
}
.tag-danger {
  background-color: #fef3f2;
  color: #b42318;
}
.tag-brown {
  background-color: #fffaeb;
  color: #b54708;
}
.tag-primary {
  background-color: #e4f8fa;
  color: #04bac7;
}
.custom-tooltip {
  text-align: left !important;
  min-width: 500px !important;
}
.overflowYscroll {
  overflow-y: auto !important;
}
.accordion-button::after {
  background-image: none !important;
  height: 0px !important;
  width: 0px !important;
  background-size: 0rem !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.actions {
  margin-right: -30px;
}
.down-arrow {
  margin-left: 6px;
  padding: 8px;
}
.accordion-button {
  cursor: auto !important;
}
.hover-invert img:hover {
  filter: invert(100%);
}
.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}
.rotate30 {
  transform: rotate(30deg);
}
.rotate-30 {
  transform: rotate(-30deg);
}
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #04bac7 !important;
}
.css-bbm1xv-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #04bac7 !important;
}
.css-1rm1z1p.Mui-selected {
  color: #04bac7 !important;
}
.css-11yukd5-MuiTabs-indicator {
  background-color: #04bac7 !important;
}
.css-1gv0vcd-MuiSlider-track {
  background-color: #04bac7 !important;
  border: none !important;
  padding: 2px 0px;
}
.css-eg0mwd-MuiSlider-thumb {
  background-color: #04bac7 !important;
  border: none !important;
  padding: 2px 0px;
}
input,
textarea {
  caret-color: #04bac7;
}
.animate {
  position: relative;
  top: 0;
  animation: scaleUpDown 0.5s infinite alternate;
  transition: transform 0.3s ease-in-out;
  /* animation: moveUpDown 0.3s infinite alternate; */
}

@keyframes scaleUpDown {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* @keyframes moveUpDown {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
} */

.overlay-right {
  left: 50% !important;
}

.p-overlaypanel-flipped:before {
  content: none !important;
}

.p-overlaypanel:after {
  content: none !important;
}
.p-overlaypanel:after,
.p-overlaypanel:before {
  content: none !important;
}

.p-overlaypanel-content {
  padding: 0rem !important;
}

/* ************************************************************************************ scroll ************************************************************************************  */
.scroll-list {
  display: flex;
  /* align-items: center; */
  /* list-style: none; */
  /* max-width: 100%; */
  overflow-x: scroll;
  scroll-behavior: smooth;
}
.scroll-list::-webkit-scrollbar {
  background: transparent;
  /* make scrollbar transparent */
  -webkit-appearance: none;
  width: 0;
  height: 0;
}
.scroll-list li {
  /* background: #ff61f6; */
  /* color: #fff; */
  padding: 8px;
  /* border-radius: 4px; */
  /* margin: 0 4px; */
  white-space: nowrap;
}
.scroll-button {
  border: 0;
  /* color: #777; */
  /* font-size: 20px; */
  margin: 0 0px;
}

.scroll-App {
  font-family: sans-serif;
  text-align: center;
  max-width: 100% !important;
  /* min-width: 300px !important; */
  /* min-width: 200px !important; */
  /* height: 100vh; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.prev {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border: 1px solid #e5e7eb;
  background-color: white;
}
.next {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border: 1px solid #e5e7eb;
  background-color: white;
}

/* Mui DataTable */
.css-pjjt8i-MuiToolbar-root {
  min-height: 0px !important;
}
.css-1rv1kxc {
  min-height: 0px !important;
}
.MuiTablePagination-selectLabel {
  margin-bottom: 0px !important;
}
.css-levciy-MuiTablePagination-displayedRows {
  margin-bottom: 0px !important;
}
.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  min-height: 62px !important;
}
thead .MuiTableRow-root {
  background-color: #f9fafb !important;
}
.css-1ygcj2i-MuiTableCell-root {
  color: #667085 !important;
  white-space: nowrap !important;
}
.css-1azl6jz-MuiTableCell-root {
  color: #667085 !important;
}
.css-1h6kq28 {
  color: #667085 !important;
}
.css-dx096b.Mui-active {
  color: #667085 !important;
}
.css-dx096b {
  margin-left: 1rem !important;
}
.css-1bigob2 {
  color: #667085 !important;
}
.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root {
  color: #667085 !important;
  margin-left: 1rem !important;
}
.css-1mxz8qt-MuiPaper-root {
  box-shadow: none !important;
}
.light-check {
  color: #d0d5dd !important;
}
.css-1chpzqh {
  margin-bottom: 0px !important;
}
.css-8nphli {
  min-height: 62px !important;
}

/* ************************************************************************************ Big Calendar ************************************************************************************  */
.rbc-toolbar-label {
  font-size: 17px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
}
.rbc-toolbar button.rbc-active {
  background-color: #04bac7 !important;
  color: white !important;
  font-weight: 500 !important;
}
.rbc-toolbar button {
  border: 1px solid #e5e7eb !important;
  font-size: 14px !important;
}
.rbc-month-view {
  border: 1px solid #e5e7eb !important;
}
.rbc-header {
  font-weight: normal !important;
  /* padding: 12px !important; */
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: #64b5f6 !important;
}
.rbc-ellipsis,
.rbc-show-more,
.rbc-row-segment .rbc-event-content,
.rbc-event-label {
  font-size: 14px !important;
}
.rbc-show-more {
  font-size: 12px !important;
}
.rbc-day-slot .rbc-event {
  border: 1px solid #ddd !important;
}
.rbc-day-slot .rbc-event-content {
  font-size: 13px !important;
}

.icon-dull {
  /* filter: grayscale(80%); */
  background-color: #e5e7eb !important;
  cursor: not-allowed;
}

/* ************************************************************************************ Chat ************************************************************************************  */

audio::-webkit-media-controls-overflow-button {
  display: none;
}

audio::-webkit-media-controls-panel {
  width: calc(100% + 1px);
}
.video-wrapper .video-controls {
  visibility: hidden !important;
}

.chat-left {
  border-radius: 8px 8px 8px 0px;
}
.chat-right {
  border-radius: 8px 8px 0px 8px;
}
.css-dt9kji-MuiBadge-root .MuiBadge-badge {
  position: absolute !important;
}
.slim-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.slim-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.slim-scroll::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle on hover */
.slim-scroll::-webkit-scrollbar-thumb:hover {
  background: #969494;
}
.notification {
  position: relative;
}

.notification-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.notification-body {
  margin-bottom: 15px;
}

.notification-time {
  font-size: 12px;
}

.notification-buttons {
  display: none;
  position: absolute;
  top: 75%;
  left: -10px;
  bottom: 20px;
  transform: translateY(-25%);
}

.notification:hover .notification-buttons {
  display: block;
}
/* ************************************************************************************ theme palette  ************************************************************************************  */
.bg-dark-grey {
  background-color: var(--dark-grey);
}
.bg-lightest-grey {
  background-color: var(--lightest-grey);
}
.bg-light-grey {
  background-color: var(--light-grey);
}
.text-black {
  color: var(--dark);
}
.border-light-grey {
  border: 1px solid var(--wheat);
}
.border-dark-grey {
  border: 1px solid var(--light-grey);
}
.dark-bg-disabled {
  background-color: var(--dark-disable);
}
.light-bg-disabled {
  background-color: var(--light-disable);
}
